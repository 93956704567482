import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from "react-country-flag";

MyFlag.defaultProps = {
size : '2em'
};

interface Props {
    countryCode : string
    size : string
}

function MyFlag(props :Props) {
    const {countryCode, size} = props;

    if(countryCode.length < 1){
        return <></>
    }

    return (
        <ReactCountryFlag countryCode={countryCode} style={{
            width: size,
            height: size,
        }} svg/>
    );
}

export default MyFlag;