import React, {useContext, useEffect} from 'react';
import {Alert, Col, Container, Image, Row} from "react-bootstrap";
import logo from "../assets/logo.png";
import {useQuery} from "@tanstack/react-query";
import {getHundredRandom} from "../repository/AdvertRepository";
import {AuthContext} from "../context/AuthContext";
import Masonry from 'react-masonry-css'
import {useSelectImageSizeByColumn} from "../hook/UseSelectImageSizeByColumn";
import MasonryCardItem from "../component/MasonryCardItem";
import {QueryKeys} from "../Constants";
import {signInAnonymously} from "@firebase/auth";
import {firebaseAuth} from "../FirebaseSetup";

ListedAdvertsPage.propTypes = {};

function ListedAdvertsPage() {
    const {imageSize, columns} = useSelectImageSizeByColumn();
    const {user, initialized} = useContext(AuthContext);
    useEffect(() => {
        async function loginAnonymous() {
            await signInAnonymously(firebaseAuth);
        }

        if (!user && initialized) {
            loginAnonymous().catch((error => {
                    console.log(error);
                }
            ));
        }
    }, [user, initialized])


    useEffect(()=>{
        window.scrollTo({left: 0, top: 0, behavior: 'auto'});
    },[])

    const {data, error, isSuccess} = useQuery(
        QueryKeys.home,
        () => getHundredRandom(),
        {
            enabled: initialized && user !== null,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false
        });


    if (data && isSuccess) {
        return (<Container className="mt-1 pt-5 mt-lg-5">
            <Masonry
                breakpointCols={columns}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {data.map((item) => {
                        return <div key={item.id}>
                            <MasonryCardItem advert={item} imageSize={imageSize}/>
                        </div>
                    }
                )}
            </Masonry>
        </Container>);
    }


    if (error) {
        return (<Alert variant={"danger"}> Ops! Couldn' t load page :( </Alert>)
    }

    return (
        <div className="vh-100">
                    <Image  className="bg-primary logo-image" roundedCircle={true} src={logo}/>
        </div>
    );
}

export default ListedAdvertsPage;