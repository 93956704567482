import React from 'react';
import {Spinner} from "react-bootstrap";


function CenterSpinner() {
    return (
        <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <Spinner  animation={'border'} variant={'primary'}/>
        </div>
    );
}

export default CenterSpinner;