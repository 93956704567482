import React from 'react';
import AppButtons from "../../component/AppButtons";
import {Col, Container, Row, Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";


AppSection.propTypes = {};

function AppSection() {
    const {i18n} = useTranslation();

    return (
        <Container>
            <Row>
                {[...Array(6)].map((_, index) => {
                    return <Col key={index} className="mb-3" md={"4"} >
                        {/*<Image src={require(`${basePath + subUrl+ i18n.resolvedLanguage}.png`)}/>*/}
                        <Image width={"100%"} src={require('../../assets/app/pic_' + (index + 1) + '_'  + i18n.resolvedLanguage + '.png')}/>
                    </Col>
                })}

            </Row>
            <Row>
                <Col>
                    <AppButtons/>

                </Col>
            </Row>
        </Container>
    );
}

export default AppSection;