import React from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

StartSection.propTypes = {

};



function StartSection() {
    const {t} = useTranslation();


    return (
            <Container>
                <Row>
                    <Col > <h1>
                        {t('pages.home.start-section.title')}
                    </h1> </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>
                            {t('pages.home.start-section.part1.title')}
                        </h5>
                        <p>
                            {t('pages.home.start-section.part1.body')}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>
                            {t('pages.home.start-section.part2.title')}
                        </h5>
                        <ul>
                            <li> {t('pages.home.start-section.part2.list.0')}</li>
                            <li> {t('pages.home.start-section.part2.list.1')}</li>
                            <li> {t('pages.home.start-section.part2.list.2')}</li>
                            <li> {t('pages.home.start-section.part2.list.3')}</li>
                            <li> {t('pages.home.start-section.part2.list.4')}</li>
                            <li> {t('pages.home.start-section.part2.list.5')}</li>
                            <li> {t('pages.home.start-section.part2.list.6')}</li>
                            <li> {t('pages.home.start-section.part2.list.7')}</li>
                            <li> {t('pages.home.start-section.part2.list.8')}</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
    );
}

export default StartSection;