import {lang} from "./interface/Lang";

//export const API_BASE_URL = 'http://localhost:5000';
export const API_BASE_URL = 'https://uzellit-back.herokuapp.com';

export const routeName = {
    advert: 'advert',
    admin: 'admin',
    home: '/',
    notFound: '*',
    report: 'report',
    tag: 'tag',
    unsubscribe: 'unsubscribe/:id',
    accountDeletion: 'account-deletion'
}

export const idName = {
    about: 'about',
    app: 'app',
    contact: 'contact',
    cookie: 'cookie',
    faq: 'faq',
    privacy: 'privacy-policy',
    start: 'start',
    terms: 'terms',
}

export const QueryKeys = {
    home: ['home']
}

export const ImageSize = {
    sm: '120x120',
    md: '240x240',
    lg: '480x480',
    xl: '960x960',
}

export const lngCode = {
    sv: 'sv',
    en: 'en'
}

export const I18nLng = new Map<string, lang>([
    [lngCode.sv, {nativeName: 'Svenska'}],
    [lngCode.en, {nativeName: 'English'}]
]);