import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

AboutSection.propTypes = {

};



function AboutSection() {
    const {t} = useTranslation();
    return (<Container>
      <Row>
          <Col>
              <h1>{t('pages.home.about-section.part1.title')}</h1>
              <p>
                  {t('pages.home.about-section.part1.body')}
              </p>
          </Col>
      </Row>
        <Row>
            <Col>
                <h5>{t('pages.home.about-section.part2.title')}</h5>
                <p>{t('pages.home.about-section.part2.sub-title')}</p>
                <ul>
                    <li>{t('pages.home.about-section.part2.list.0')}</li>
                    <li>{t('pages.home.about-section.part2.list.1')}</li>
                    <li>{t('pages.home.about-section.part2.list.2')}</li>
                    <li>{t('pages.home.about-section.part2.list.3')}</li>
                </ul>
            </Col>
        </Row>
    </Container>);
}

export default AboutSection;