import React, {useEffect, useState} from 'react';
import {Advert} from "../interface/Advert";
import InfiniteScroll from "react-infinite-scroll-component";
import {getBySeller} from "../repository/AdvertRepository";
import MySpinner from "./MySpinner";
import {getUniqueListBy} from "../util/functions";
import AdminCardItem from "./AdminCardItem";

SellerAdverts.propTypes = {
};

interface Props {
    userId : string
}

function SellerAdverts(props : Props) {
    const {userId} = props;

    const [adverts, setAdverts] = useState<Advert[]>([]);
    const [endReached, setEndReached] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [error, setError] = useState<string | null>( null);
    const limit = 50;


    useEffect(()=>{
        fetchData().then();
    }, [])


    async function fetchData() {
        setIsBusy(true);
        if(isBusy) return;
        try{
         let result = await getBySeller(adverts.length, userId, limit);
         setEndReached(result.length !== limit);
         setAdverts((oldState)=> [...getUniqueListBy([...oldState, ...result], 'id')])
        }catch (e :any) {
            setError(e.toString);
        }finally {
            setIsBusy(false);
        }
    }


    return (
        <div className={"d-flex flex-column"}>
          <div className="my-3">
              <h5>
                  Adverts:
              </h5>
          </div>
            <div>

            </div>
                    <InfiniteScroll
                        dataLength={adverts.length} //This is important field to render the next data
                        next={fetchData}
                        hasMore={!endReached}
                        loader={
                            <div className="d-flex justify-content-center">
                                <MySpinner/>
                            </div>
                          }
                        endMessage={
                            <b style={{ textAlign: 'center' }}>
                                <b>Nothing more to load</b>
                            </b>
                        }
                    >
                        {
                            adverts.map(e =>
                              <AdminCardItem advert={e} setAdverts={setAdverts}/>
                            )
                        }

                    </InfiniteScroll>

        </div>

    );
}

export default SellerAdverts;