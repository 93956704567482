import React from 'react';
import PropTypes from 'prop-types';
import {Badge} from "react-bootstrap";

WrappedTags.propTypes = {

};


interface Props {
    tags: string[]
}

function WrappedTags(props : Props) {
    const {tags} = props;


    return (
        <div className="d-flex flex-wrap">
            {tags.map((value, index) => {
                return <Badge pill bg="primary" className="me-1 mb-1" key={index}>
                    #{value}
                </Badge>
            })
            }
        </div>
    );
}

export default WrappedTags;