import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Card} from "react-bootstrap";
import MyCarousel from "./MyCarousel";
import Separator from "./Separator";
import WrappedTags from "./WrappedTags";
import {Advert} from "../interface/Advert";
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
import {disableAdvert} from "../repository/AdvertRepository";
import MySpinner from "./MySpinner";

interface Props {
    advert: Advert
    setAdverts: React.Dispatch<React.SetStateAction<Advert[]>>
}

function AdminCardItem(props: Props) {
    const {advert, setAdverts} = props;
    const {id, currency, price, description, tags, imageUrls, title} = advert;
    const [modalShow, setModalShow] = useState(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    async function handleCallback(advertId: string) {
        setIsBusy(true);
        setError(null);
        try {
            let result = await disableAdvert(id, advert.user.id);
            if (result) {
              setAdverts(oldState => oldState.filter(item => item.id !== advertId))
            }
        } catch (e: any) {
            setError(e.toString);
        } finally {
            setIsBusy(false);
        }
    }

    return (
        <div key={id}>
            <Card key={id} className="mb-3">
                <MyCarousel imgUrls={imageUrls}/>
                <Card.Body>
                    <h3>{price} {currency}</h3>
                    <Card.Title>{title}</Card.Title>
                    <Separator/>
                    <Card.Text>
                        {description}
                    </Card.Text>
                    <div className="mb-3">
                        <WrappedTags tags={tags}/>

                    </div>
                    {isBusy ? <div className="d-flex justify-content-center">
                        <MySpinner/>
                    </div> : <Button onClick={() => setModalShow(true)}>Disable</Button>}
                    {error && <Alert variant={"warning"}>{error}</Alert>}
                </Card.Body>
            </Card>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                advertId={id}
                title={"Disable Advert"}
                body={"Are you sure you want to disable the advert, this cant be reversed."} callback={handleCallback}/>
        </div>
    );
}

export default AdminCardItem;