import React, {MutableRefObject, useEffect, useRef} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import StartSection from "./StartSection";
import {idName} from "../../Constants";
import FaqSection from "./FaqSection";
import AboutSection from "./AboutSection";
import ContactSection from "./ContactSection";
import PrivacySection from "./PrivacySection";
import TermsSection from "./TermsSection";
import {useParams} from "react-router-dom";
import AppSection from "./AppSection";
import CookiesSection from "./CookiesSection";

HomePage.propTypes = {};

function HomePage() {
    const params = useParams();
    const startRef = useRef<null | HTMLDivElement>(null);
    const appRef = useRef<null | HTMLDivElement>(null);
    const faqRef = useRef<null | HTMLDivElement>(null);
    const aboutRef = useRef<null | HTMLDivElement>(null);
    const contactRef = useRef<null | HTMLDivElement>(null);
    const privacyRef = useRef<null | HTMLDivElement>(null);
    const cookiesRef = useRef<null | HTMLDivElement>(null);
    const termsRef = useRef<null | HTMLDivElement>(null);


    useEffect(() => {
        function scrollToRef(ref: MutableRefObject<HTMLDivElement | null>) {
            setTimeout(() => {
                ref.current?.scrollIntoView({ behavior: 'smooth'});
            }, 300); // Delay for 300 milliseconds
        }


            switch (params.id ?? idName.start){
                case idName.start:
                    window.scrollTo(0, 0);
                    break;
                case idName.app:
                    scrollToRef(appRef);
                    break;
                case idName.faq:
                    scrollToRef(faqRef);
                    break;
                case idName.about:
                    scrollToRef(aboutRef);
                    break;
                case idName.contact:
                    scrollToRef(contactRef);
                    break;
                case idName.privacy:
                    scrollToRef(privacyRef);
                    break;
                case idName.cookie:
                    scrollToRef(cookiesRef);
                    break;
                case idName.terms:
                    scrollToRef(termsRef);
                    break;
            }



    }, [params])


    return (
        <Container id={'home-page'} className={"py-5 text-light"}>
            <div ref={startRef}>
                <StartSection/>
            </div>
            <div ref={appRef}>
                <AppSection/>
            </div>
            <div ref={faqRef}>
                <FaqSection/>
            </div>
            <div ref={aboutRef}>
                <AboutSection/>
            </div>
            <div ref={contactRef}>
                <ContactSection/>
            </div>
            <div ref={privacyRef}>
                <PrivacySection/>
            </div>
            <div ref={cookiesRef}>
                <CookiesSection/>
            </div>
            <div ref={termsRef}>
                <TermsSection/>
            </div>
        </Container>
    );
}

export default HomePage;