import React, {useState} from 'react';
import {Report} from "../interface/Report";
import {Alert, Button, Card, Col, Container, Row} from "react-bootstrap";
import UserProfileMail from "./UserProfileMail";
import {getCompleteName} from "../util/functions";
import Separator from "./Separator";
import {disableUser} from "../repository/UserRepository";
import MySpinner from "./MySpinner";
import SellerAdverts from "./SellerAdverts";

interface Props {
    report: Report
    setReport: React.Dispatch<React.SetStateAction<Report | null>>
}

function ReportCard(props: Props) {
    const {setReport, report} = props;
    const {subject, description, reporter, regarding, number} = report;
    const [isBusy, setIsBusy] = useState(false);


    async function handleClick() {
        if (isBusy) return;
        setIsBusy(true);
        try {
            let value = await disableUser(regarding.id, !regarding.disabled);
            setReport((oldState: any) => ({
                ...oldState,
                regarding: {
                    ...oldState.regarding,
                    disabled: value
                }
            }));
        } catch (e) {
            console.log(e)
        } finally {
            setIsBusy(false);
        }
    }

    return (
        <Container>
            <Card.Body>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Reporter</Card.Title>
                                <UserProfileMail user={reporter}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Separator/>
                <Row>
                    <Col>
                        <span>
                            <b>{getCompleteName(reporter)}</b> has reported <b>{getCompleteName(regarding)}</b> for <b>{subject}</b>
                        </span>
                        <p>Description: {description} </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Alert className="text-center"
                                   variant={regarding.disabled ? "danger" : "success"}>User {regarding.disabled ? 'Disabled' : 'Enabled'}</Alert>
                            <Card.Body>
                                <Card.Title>Reported</Card.Title>
                                <UserProfileMail user={regarding}/>
                                {isBusy &&
                                <div className="d-flex justify-content-center">
                                    <MySpinner/>
                                </div>
                                }
                                {!isBusy && <Button
                                    onClick={() => handleClick()}
                                    variant={regarding.disabled ? "info" : "warning"}>{regarding.disabled ? 'Enable' : 'Disable'} user</Button>}
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
                {!regarding.disabled && <Row>
                    <Col>
                        <SellerAdverts userId={regarding.id}/>
                    </Col>
                </Row>
                }
            </Card.Body>
        </Container>
    );
}

export default ReportCard;