import React, {useContext, useEffect, useRef} from 'react';
import {Alert, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {AuthContext} from "../context/AuthContext";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {addView, getAdvert} from "../repository/AdvertRepository";
import MyCarousel from "../component/MyCarousel";
import UserProfile from "../component/UserProfile";
import DateViewFavorite from "../component/DateViewFavorite";
import WrappedTags from "../component/WrappedTags";
import Separator from "../component/Separator";
import {arrayIsEqual} from "../util/functions";
import MyMap from "../component/MyMap";
import MyFlag from "../component/MyFlag";
import {QueryKeys} from "../Constants";
import {Advert} from "../interface/Advert";
import {signInAnonymously} from "@firebase/auth";
import {firebaseAuth} from "../FirebaseSetup";


type AdvertParams = {
    id: string
}


function AdvertPage() {
    const {id} = useParams<AdvertParams>();
    const queryClient = useQueryClient();
    const viewAdded = useRef(false);
    const {user, initialized} = useContext(AuthContext);

    useEffect(() => {
        async function loginAnonymous() {
            await signInAnonymously(firebaseAuth);
        }

        if (!user && initialized) {
            loginAnonymous().catch((error => {
                    console.log(error);
                }
            ));
        }
    }, [user, initialized])


    useEffect(() => {
        function handleAddView(id: string) {
            viewAdded.current = true;
            addView(id).then(() => {
                const data: Advert[] | undefined = queryClient.getQueryData(QueryKeys.home);
                const advert: Advert | undefined = data?.find(advert => advert.id === id);
                if (advert != null) {
                    advert.views++;
                    queryClient.setQueryData(QueryKeys.home, (oldData: Advert[] | undefined) => {
                        if (oldData == null) return;
                        const index = oldData?.findIndex(item => item.id === id);
                        oldData[index] = advert;
                        return [...oldData];
                    })
                }
            })
        }

        if (id != null && !viewAdded.current && initialized) {
            handleAddView(id);

        }

    }, [id, initialized, queryClient])

    useEffect(()=>{
        window.scrollTo({left: 0, top: 0, behavior: 'auto'});
    },[])


    const {data, error, isSuccess} = useQuery(
        [id],
        () => getAdvert(id),
        {enabled: initialized && id != null});


    if (data && isSuccess) {
        return <Container className="my-5 py-5">
            <Row className="justify-content-center">
                <Col lg="8">
                    <Card>
                        <Card.Body>
                            <UserProfile user={data.user}/>
                        </Card.Body>
                        <MyCarousel imgUrls={data.imageUrls}/>
                        <Card.Body>
                            <h3>{data.price} {data.currency}</h3>
                            <Card.Title>{data.title}</Card.Title>
                            <Separator/>
                            <Card.Text>
                                {data.description}
                            </Card.Text>
                            <WrappedTags tags={data.tags}/>

                            <Separator/>
                            <DateViewFavorite advert={data}/>
                            <Separator/>
                            <MyFlag countryCode={data.user.countryCode}/>
                            {!arrayIsEqual(data.user.latLng, [0.0, 0.0]) && <MyMap latLng={data.user.latLng}/>}
                        </Card.Body>


                    </Card>
                </Col>
            </Row>
        </Container>;
    }


    if (error) {
        return (<Alert variant={"danger"}> Ops! Couldn' t load page :( </Alert>)
    }

    return (
        <Container fluid={true} className={"h-100 d-flex flex-column justify-content-center align-items-center"}>
            <Spinner variant={"primary"} animation={"border"}/>
        </Container>
    );
}

export default AdvertPage;