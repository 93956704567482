import React from 'react';
import {Card} from "react-bootstrap";
import {getLocaleFormatDateString, toFixedIfNecessary, urlToResizedImageUrl} from "../util/functions";
import {useNavigate} from "react-router-dom";
import {Advert} from "../interface/Advert";
import UserProfile from "./UserProfile";
import MyFlag from "./MyFlag";
import FavoriteView from "./FavoriteView";
import {routeName} from "../Constants";


MasonryCardItem.propTypes = {};

interface Props {
    advert: Advert
    imageSize: string,

}

function MasonryCardItem(props: Props) {
    const navigate = useNavigate();
    const {id, title, currency, favoriteCount, imageUrls, user, price, createdAt, views} = props.advert;
    const {countryCode} = user;

    function handleClick(id: string) {
        navigate(id);
    }


    return (
        <Card style={{cursor:"pointer"}} onClick={() => handleClick(id)} key={id}>
            <Card.Body>
                <UserProfile size={45} user={user}/>

            </Card.Body>

            <Card.Img variant="top" src={urlToResizedImageUrl(imageUrls[0], props.imageSize)}/>
            <Card.Body>
                <h3>{`${toFixedIfNecessary(price, 2)} ${currency}`}</h3>
                <Card.Title>{title.trim()}</Card.Title>
                <div className="d-flex justify-content-between align-items-center">
                    <MyFlag countryCode={countryCode} size="1.5em"/>
                    <span>{getLocaleFormatDateString(createdAt)}</span>
                    <FavoriteView favoriteCount={favoriteCount} views={views}/>
                </div>
            </Card.Body>
        </Card>
    );
}

export default MasonryCardItem;