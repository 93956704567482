import { initializeApp } from "@firebase/app";
import {getAuth} from  '@firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAgfZvMp6cMVWc1ZM6vhZC7d3YkG6m1c6g",
    authDomain: "pc-api-7825005173531345054-403.firebaseapp.com",
    projectId: "pc-api-7825005173531345054-403",
    storageBucket: "pc-api-7825005173531345054-403.appspot.com",
    messagingSenderId: "813451003742",
    appId: "1:813451003742:web:8dfc260a9be6db365831cf",
    measurementId: "G-PSWFTDG36F"
};

const  firebaseApp = initializeApp(firebaseConfig);
export const  firebaseAuth = getAuth(firebaseApp);