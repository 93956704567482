import {deleteData, putData} from "./client";


export async function unSubscribe(id: string) : Promise<string>{

    try{
        let result = await putData(`${baseUrl}/unsubscribe/${id}`);
       return  await result.text();
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function disableUser(id: string, disable : boolean) : Promise<boolean>{

    try{
        let result = await putData(`${baseUrl}/disable/${id}/${disable}`);
        return  await result.json();
    }catch(e){
        console.log(e);
        throw e;
    }
}


export async function deleteUser() : Promise<boolean>{
    try {
        let result = await deleteData(`${baseUrl}/delete`);
        return await result.json();
    }catch (e) {
        console.log(e);
        throw e;
    }
}


const baseUrl = '/user'



