import {useEffect, useState} from 'react';
import {useWindowSize} from "./UseWindowSize";
import {ImageSize} from "../Constants";


export function useSelectImageSizeByColumn() {
    const windowSize = useWindowSize();
    const [columns, setColumns] = useState<number>(setNewColumns);
    const [imageSize, setImageSize] = useState<string>(setNewImageWidth);

    useEffect(() => {
        function handleWindowResize() {
         setImageSize(setNewImageWidth);
         setColumns(setNewColumns);
        }

        handleWindowResize();

        return () => {
           handleWindowResize();
        };
    }, [windowSize]);


    function setNewImageWidth() {
        let availableWidth = windowSize.innerWidth / columns;
        if (availableWidth > 480) {
            return ImageSize.xl;
        } else if (availableWidth > 240) {
            return ImageSize.lg;
        } else {
            return ImageSize.md;
        }
    }

    function setNewColumns() {
        let windowWidth = windowSize.innerWidth ;
        if (windowWidth < 500) {
            return 1;
        } else if (windowWidth < 700) {
            return 2;
        } else if (windowWidth < 1100) {
            return 3;
        } else return 4;
    }

    return {imageSize, columns};
}

