import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {Alert, Button, Container} from "react-bootstrap";
import {AuthContext} from "../../context/AuthContext";
import MySpinner from "../../component/MySpinner";
import {firebaseAuth} from "../../FirebaseSetup";
import LoginForm from "../../component/LoginForm";
import {routeName} from "../../Constants";


function AdminPage() {
    const {user, isAdmin, initialized} = useContext(AuthContext);
    const [isBusy, setIsBusy] = useState(initialized);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if (!isAdmin && isInSubPath() && initialized) {
            goBack();
        }

    }, [ initialized, isAdmin, location]);

    

    if (isBusy ) {
        return <Container className="d-flex justify-content-center align-items-center">
            <MySpinner/>
        </Container>
    }



    function isInSubPath() {
        return location.pathname.includes(routeName.report)
            || location.pathname.includes(routeName.tag);
    }


    function goBack() {
        navigate("./");
    }

    


    async function handleSignOut() {
        setIsBusy(true);
        try {
            await firebaseAuth.signOut().then(
                () => navigate("")
            );
        } catch (e) {

        } finally {
            setIsBusy(false);
        }
    }

    return (
        <Container style={{minHeight: "100vh"}}
                   className="d-flex flex-column  text-light">
            {!user && <div className="d-flex justify-content-center">
                <LoginForm/>
            </div> }
            {user && !isAdmin && <Alert variant={"warning"}>401 Unauthorized</Alert>}
            {user && isAdmin && <Outlet/>}
            {user &&
            <div className="d-flex mt-5 justify-content-evenly">
            <Button onClick={handleSignOut}>Sign out</Button>
                {isInSubPath() && <Button onClick={() => goBack()}>Back to Menu</Button>}
            </div>}
        </Container>
    );
}

export default AdminPage;