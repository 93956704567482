import React, {useContext} from 'react';
import MyNavBar from "./component/MyNavBar";
import {Route, Routes} from "react-router-dom";
import ListedAdvertsPage from "./page/ListedAdvertsPage";
import UnsubscribePage from "./page/UnsubscribePage";
import NotFoundPage from "./page/NotFoundPage";
import AdvertPage from "./page/AdvertPage";
import BottomAppCanvas from "./component/BottomAppCanvas";
import AppButtons from "./component/AppButtons";
import HomePage from "./page/home/HomePage";
import {routeName} from "./Constants";
import AdminPage from "./page/admin/AdminPage";
import ReportPage from "./page/admin/ReportPage";
import AdminNavigation from "./component/AdminNavigation";
import TagPage from "./page/admin/PageTag";
import AccountDeletion from "./page/account_deletion/AccountDeletion";

function App() {



    return (
        <div className="h-100 my-5 py-5">
            <MyNavBar/>
            <Routes>
                <Route path={routeName.home}>
                    <Route index element={<HomePage/>}/>
                    <Route path={':id'} element={<HomePage/>}/>
                </Route>
                <Route path={routeName.advert}>
                    <Route index element={<ListedAdvertsPage/>}/>
                    <Route path={':id'} element={<AdvertPage/>}/>
                </Route>
                <Route path={routeName.admin} element={<AdminPage/>}>
                    <Route path={''} element={<AdminNavigation/>}/>
                    <Route path={routeName.report} element={<ReportPage/>}/>
                    <Route path={routeName.report + '/:id'} element={<ReportPage/>}/>
                    <Route path={routeName.tag} element={<TagPage/>}/>
                </Route>
                <Route path={routeName.accountDeletion} element={<AccountDeletion/>}/>
                <Route path={routeName.unsubscribe} element={<UnsubscribePage/>}/>
                <Route path={routeName.notFound} element={<NotFoundPage/>}/>
            </Routes>
            <div className="p-5">
                <AppButtons/>
            </div>
            <BottomAppCanvas/>
        </div>
    );
}

export default App;
