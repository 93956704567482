import React from 'react';
import {Alert, Container} from "react-bootstrap";
import {useLocation} from "react-router-dom";

function NotFoundPage() {
    return (<Container className="vh-100 vw-100 d-flex justify-content-center align-items-center">
        <Alert variant="warning">
            <Alert.Heading>404 PAGE NOT FOUND</Alert.Heading>
            <p>
                Cant find page :/
            </p>
            <hr />
            <p className="mb-0">
                {window.location.href}
            </p>
        </Alert>
    </Container>);

}

export default NotFoundPage;