import React from 'react';
import {getLocaleFormatDateString} from "../util/functions";
import FavoriteView from "./FavoriteView";
import {Advert} from "../interface/Advert";

DateViewFavorite.propTypes = {};

interface Props {
    advert: Advert
}

function DateViewFavorite(props: Props) {
    const {createdAt, favoriteCount, views} = props.advert;
    return (
        <div className="d-flex justify-content-between">
            <span>{getLocaleFormatDateString(createdAt)}</span>

            <div>
                <FavoriteView favoriteCount={favoriteCount} views={views}/>
            </div>
        </div>
    );
}

export default DateViewFavorite;