import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Report} from "../../interface/Report";
import MySpinner from "../../component/MySpinner";
import ReportCard from "../../component/ReportCard";
import {getReportById} from "../../repository/ReportRepository";

ReportPage.propTypes = {};


function ReportPage() {
    let params = useParams();

    const [id, setId] = useState<string>(params.id ?? '');
    const [report, setReport] = useState<Report | null>(null);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (id.length > 3) {
            (async () => {
                await getReport(id, signal);
            })();
        }
        return () => {

            controller.abort();
        }
    }, [])


    async function getReport(id: string, signal: AbortSignal | undefined = undefined) {
        try {
            const report = await getReportById(id, signal);
            setReport(report);
        } catch (e: any) {
            setError(e);
        } finally {
            setIsBusy(false);
        }
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (isBusy) return;
        setReport(null);
        setIsBusy(true);
        setError(null);
        await getReport(id);
    }

    return (

        <Row className="justify-content-center">
            <Col lg={6}>
                <Card>
                    <Card.Body>
                        <div className={"d-flex flex-column text-dark"}>
                            <Row>
                                <Col>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Report Id:</Form.Label>
                                            <div className={"d-flex "}>
                                                <Form.Control value={id} onChange={(value) => setId(value.target.value)}
                                                              type="text"
                                                              placeholder="Enter id"/>
                                                <Button className="ms-1" variant="primary" type="submit">
                                                    Submit
                                                </Button>

                                            </div>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {isBusy &&
                                    <div className="d-flex justify-content-center">
                                        <MySpinner/>
                                    </div>
                                    }
                                    {error && <Alert variant={"warning"}>{error.toString()}</Alert>}
                                    {report && <ReportCard setReport={setReport} report={report}/>}
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>


    );
}

export default ReportPage;