import React, {useCallback, useState} from 'react';
import {GoogleMap, MarkerF, useJsApiLoader} from "@react-google-maps/api";


MyMap.propTypes = {};

interface Props {
    latLng: number[]
}

function MyMap(props: Props) {
    const {latLng} = props;

    const [map, setMap] = useState< google.maps.Map | null>(null);

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAgfZvMp6cMVWc1ZM6vhZC7d3YkG6m1c6g"
    });

    const containerStyle = {
        width: '100%',
        aspectRatio: '20/8'
    };

    const center = {
        lat: latLng[0], lng: latLng[1]
    };


    const onLoad = useCallback(function callback(map: google.maps.Map) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        // map.setZoom(8);
        setMap(map)
    }, []);

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, []);


    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={11}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */}
            <></>
        </GoogleMap>
    ) : <></>
}

export default MyMap;