import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {LinkItEmail} from "react-linkify-it";
import {useTranslation} from "react-i18next";

ContactSection.propTypes = {};

interface Section {
    title: string
    subtitle: string
}

function ContactSection() {
    const {t} = useTranslation();
    const list: Section[] = JSON.parse(t('pages.contact_us.parts'));
    return (<Container>
        <div className="d-flex flex-column mb-3">
            <h1>{t('pages.contact_us.title')}</h1>
            <p>{t('pages.contact_us.subtitle')}</p>
        </div>
        <LinkItEmail>
            <Row>
                {list.map((e, index) => {
                    return <Col key={index} lg={6} className="mb-3">
                        <h4>{e.title}</h4>
                        <p>{e.subtitle}</p>
                    </Col>
                })}
            </Row>
        </LinkItEmail>
    </Container>);
}

export default ContactSection;