import {getData, putData} from "./client";

export async function searchTags(search: string, disabled: boolean): Promise<string[]> {
    try {
        return await (await getData(`/tag/admin-search?${new URLSearchParams({
            search,
            'disabled': String(disabled)
        }).toString()}`)).json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}


export async function disableTag(tag: string, disable: boolean): Promise<boolean> {
    try {
        return await (await putData(`/tag/disable?${new URLSearchParams({
            tag,
            'disable': String(disable)
        }).toString()}`,)).json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}
