import {getData, putData} from "./client";
import {Advert} from "../interface/Advert";


export async function getHundredRandom(): Promise<Advert[]> {
    try {
        let result = await getData(`${baseUrl}/random`);
        return await result.json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}


export async function getBySeller(skip: number, userId: string, limit: number):Promise<Advert[]>{
    let params  = {
        userId,
        skip: skip.toString(),
        limit: limit.toString(),
        shipsTo: 'WW',
        orderBy:'fresh',
        distance: (-1).toString()
    };



    let queryParams =  new URLSearchParams(params).toString();

    try {
        let result = await getData(`${baseUrl}/by-seller?${queryParams}`);
        return await result.json();
    } catch (e) {
        console.log(e);
        throw e;
    }


}

export async function getAdvert(id: string | undefined): Promise<Advert> {
    try {
        let result = await getData(`${baseUrl}/${id}`);
        return await result.json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function disableAdvert(id: string, userId : string): Promise<boolean> {
    try {
      let result =  await putData(`${baseUrl}/disable/${id}/${userId}`);
      return await result.json();
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export async function addView(id: string): Promise<void> {
    try {
        await putData(`${baseUrl}/add-view/${id}`);
    } catch (e) {
        console.log(e);
        throw e;
    }
}


const baseUrl = '/advert'



