import React, {SyntheticEvent, useState} from 'react';
import {Alert, Badge, Button, Card, Col, Container, Form, Nav, Row} from "react-bootstrap";
import {disableTag, searchTags} from "../../repository/TagRepository";
import CenterSpinner from "../../component/CenterSpinner";



function PageTag() {
    const [tags, setTags] = useState<string[]>([])
    const [disableTags, setDisableTags] = useState<string[]>([])
    const [search, setSearch] = useState<string>('')
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');


    async function handleSearch() {
        if (isLoading) return;
        setIsLoading(true);
        setError('')
        try {
            const result = await searchTags(search, disabled);
            if (disabled) {
                setDisableTags(result);
            } else {
                setTags(result);
            }
        } catch (e: any) {
            setError(String(e))
        } finally {
            setIsLoading(false);
        }
    }


    async function handleClickOnTag(tag: string) {
        if (isLoading) return;
        setIsLoading(true);
        setError('');
        try {
            const result = await disableTag(tag, !disabled);
            if (result) {
                setTags((oldState) => [...oldState.filter(e => e !== tag)])
                setDisableTags((oldState) => [tag, ...oldState])
            } else {
                setDisableTags((oldState) => [...oldState.filter(e => e !== tag)])
                setTags((oldState) => [tag, ...oldState])
            }
        } catch (e) {
            setError(String(e))
        } finally {
            setIsLoading(false);
        }
    }


    function handleSelect(eventKey: string | null, event: SyntheticEvent<unknown>) {
        if (isLoading) return;
        setDisabled(Number.parseInt(eventKey ?? '0') !== 0);
    }


    function removeIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    className="bi bi-trash-fill" viewBox="0 0 16 16">
            <path
                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
        </svg>;
    }

    function undoIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
            <path
                d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
        </svg>
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Nav variant="tabs" activeKey={!disabled ? 0 : 1}
                                 onSelect={handleSelect}>
                                <Nav.Item>
                                    <Nav.Link eventKey={0}>Tags</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={1}>Disabled</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body className='d-flex flex-column'>
                            <div className="d-flex">
                                <Form.Control type="text" placeholder="Search tag"
                                              onChange={(event => setSearch(event.target.value))}/>
                                <Button disabled={!disabled && search.length < 1} className='ms-1'
                                        onClick={() => handleSearch()}>Search</Button>
                            </div>
                            <div style={{height: "500px", overflow: "auto"}}>
                                {error.length > 0 && <Alert variant={"warning"}>{error.toString()}</Alert>}
                                {isLoading ? <CenterSpinner/> :
                                    [...(disabled ? disableTags : tags)].map(e => <Badge key={e}
                                                                                         style={{cursor: "pointer"}}
                                                                                         onClick={() => handleClickOnTag(e)}
                                                                                         className='m-1'>{e} {disabled ? undoIcon() : removeIcon()}</Badge>)
                                }

                            </div>
                        </Card.Body>

                    </Card>

                </Col>
            </Row>
        </Container>

    );
}

export default PageTag;