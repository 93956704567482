import {ImageSize} from "../Constants";
import {User} from "../interface/User";

export function urlToResizedImageUrl(url: string, size: string = ImageSize.md): string {
    if (!url || url.length < 1) {
        return '';
    }
    let pos = url.lastIndexOf('.');
    return url.substring(0, pos) + `_${size}.` + url.substring(pos + 1);
}

export function toFixedIfNecessary( value : number, dp : number ){
    return +parseFloat(String(value)).toFixed( dp );
}

export function getLang() {
    if (navigator.languages !== undefined)
        return navigator.languages[0];
    return navigator.language;
}

export function getLocaleFormatDateString(millisUnix : number) {
    return  new Date(millisUnix).toLocaleDateString(getLang(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
}

export function getUniqueListBy(arr : any[], key : string) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

export function getCompleteName(user: User) {
    return `${user.firstName} ${user.surname}`.trim();
}
export function arrayIsEqual(array1 : any[], array2 : any[]){
    const array2Sorted = array2.slice().sort();
    return  array1.length === array2.length && array1.slice().sort().every((value, index) => {
        return value === array2Sorted[index];
    });
}