import React, {useEffect, useState} from 'react';
import {Offcanvas} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import AppButtons from "./AppButtons";
import {useLocation} from "react-router-dom";
import {idName, routeName} from "../Constants";

function BottomAppCanvas() {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    let location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes(routeName.admin) && !location.pathname.includes(idName.privacy) && !location.pathname.includes(idName.terms)) {
            setTimeout(() => setShow(true), 1000)
            ;
        }
    }, [])


    const handleClose = () => setShow(false);


    return (
        <Offcanvas style={{height: "fit-content"}} show={show} onHide={handleClose} placement="bottom">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('offcanvas.get_app.title')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="px-3 pb-3 text-center">
                <div className="d-flex flex-column">
                    <b className="mb-3">{t('offcanvas.get_app.body')}</b>
                    <AppButtons/>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default BottomAppCanvas;