import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {LinkItEmail} from 'react-linkify-it';
import TranslatedTextWithLink from "../../component/TranslatedTextWithLink";


FaqSection.propTypes = {};

interface Faq {
    q: string
    a: string
}


function FaqSection() {
    const {t} = useTranslation();

    const list: Faq[] = JSON.parse(t('pages.home.faq-section.list'));
    const subList: string[] = JSON.parse(t('pages.home.faq-section.subtitle-list'));


    return (
        <Container>
            <h1 className="mb-3">{t('pages.home.faq-section.title')}</h1>

            <Row>
                <Col>
                    <h5 className="mb-3">{t('pages.home.faq-section.subtitle')}</h5>
                    <ul  >
                        {subList.map((e:string, index: number) =>{
                          return  <li key={index}>{e}</li>
                        })}
                    </ul>

                </Col>
            </Row>


            {list.map((e, index) => {

                return <Row key={index}>
                    <Col>
                        <b>{index + 1 + ': ' + e.q.toUpperCase()}</b>
                        <LinkItEmail>
                            <TranslatedTextWithLink text={e.a}/>

                        </LinkItEmail>
                    </Col>
                </Row>
            })}
        </Container>);
}

export default FaqSection;