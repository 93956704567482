import React from 'react';
import {Button, Modal, ModalProps} from "react-bootstrap";


interface Props {
    advertId: string
    title: string
    body: string

    callback(advertId: string): void
}

function MyVerticallyCenteredModal(props: Props & ModalProps) {
    const {advertId, body, title, callback, onHide} = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {body}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button onClick={() =>{
                    callback(advertId);
                    if (onHide) {
                        onHide();
                    }
                }}>Ok</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MyVerticallyCenteredModal;