import React, {useState} from 'react';
import {Carousel} from 'react-bootstrap';
import {urlToResizedImageUrl} from "../util/functions";
import {ImageSize} from "../Constants";


MyCarousel.propTypes = {};

interface Props {
    imgUrls: string[]
}

function MyCarousel(props: Props) {
    const {imgUrls} = props;
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number, e: Record<string, unknown> | null) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel  controls={imgUrls.length > 1} indicators={imgUrls.length > 1} activeIndex={index} onSelect={handleSelect}>
            {imgUrls.map((value, index) => {
                return <Carousel.Item key={index} style={{backgroundColor: "whitesmoke"}}>
                    <img style={{objectFit: "contain", aspectRatio: "16/9"}}
                        className="d-block w-100"
                        src={urlToResizedImageUrl(value, ImageSize.xl)}
                        alt={"image " + index + 1}
                    />
                </Carousel.Item>
            })}
        </Carousel>
    );
}

export default MyCarousel;