import React from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {I18nLng, idName, routeName} from "../Constants";


MyNavBar.propTypes = {};

function MyNavBar() {
    const {t, i18n} = useTranslation();
    let navWrapper : HTMLDivElement | null;
    let toggleButtonRef : any;

   function handleDocumentClick(e: any){
       if(!navWrapper?.contains(e.target)){
        toggleButtonRef.click();
       }
   }

    const onExpand = (expanded:boolean) => {
        if (expanded) {
            document.addEventListener('click', handleDocumentClick, true);
        }else {
            document.removeEventListener('click', handleDocumentClick, true);
        }
    }


    return (
        <div ref={(e: HTMLDivElement | null) => (navWrapper = e)}>
            <Navbar ref={(e: HTMLDivElement | null) => (navWrapper = e)} collapseOnSelect onToggle={onExpand} fixed={"top"} bg="primary" variant="dark"
                    expand="lg">
                <Container>
                    <Navbar.Brand as={Link} to={routeName.home}>
                        <h2 style={{fontWeight: "bold"}} className="p-0 m-0">
                            uZelliT
                        </h2></Navbar.Brand>
                    <Navbar.Toggle ref={(e : any) => (toggleButtonRef = e)} aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav ">
                        <Nav className="me-auto w-100">
                            <Nav.Link eventKey={0} as={Link} to={routeName.home}>{t('nav_bar.button_home')}</Nav.Link>
                            <Nav.Link eventKey={1} as={Link}
                                      to={routeName.advert}>{t('nav_bar.button_adverts')}</Nav.Link>
                            <Nav.Link eventKey={2} as={Link}
                                      to={routeName.home + idName.app}>{t('nav_bar.button_app')}</Nav.Link>
                            <Nav.Link eventKey={3} as={Link}
                                      to={routeName.home + idName.faq}>{t('nav_bar.button_faq')}</Nav.Link>
                            <Nav.Link eventKey={4} as={Link}
                                      to={routeName.home + idName.about}>{t('nav_bar.button_about')}</Nav.Link>
                            <Nav.Link eventKey={5} as={Link}
                                      to={routeName.home + idName.contact}>{t('nav_bar.button_contact')}</Nav.Link>
                            <NavDropdown title={t('nav_bar.dropdown_legal')} id="nav-dropdown-legal">
                                <NavDropdown.Item as={Link} eventKey={6}
                                                  to={routeName.home + idName.privacy}>{t('nav_bar.button_privacy')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} eventKey={7}
                                                  to={routeName.home + idName.cookie}>{t('nav_bar.button_cookies')}</NavDropdown.Item>
                                <NavDropdown.Item as={Link} eventKey={8}
                                                  to={routeName.home + idName.terms}>{t('nav_bar.button_terms')}</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className="ms-lg-auto"
                                         title={I18nLng.get(i18n.resolvedLanguage)?.nativeName ?? ''}
                                         id="nav-dropdown-legal">
                                {
                                    Array.from(I18nLng.keys()).filter(e => e !== i18n.language).map((lng, index) =>
                                        <NavDropdown.Item onClick={() => i18n.changeLanguage(lng)}
                                                          key={index}>{I18nLng.get(lng)?.nativeName ?? ''}</NavDropdown.Item>)
                                }
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>

    );
}

export default MyNavBar;