import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../context/AuthContext";
import {unSubscribe} from "../repository/UserRepository";
import {Alert, Container} from "react-bootstrap";
import MySpinner from "../component/MySpinner";

UnsubscribePage.propTypes = {};

function UnsubscribePage() {
    const {initialized} = useContext(AuthContext);
    let params = useParams();
    const {t} = useTranslation();
    const [status, setStatus] = useState<string>('loading');
    const [data, setData] = useState<string>('');


    useEffect(() => {
        function unsubscribe() {
            return unSubscribe(params.id ?? '')
        }

        if (initialized) {
            unsubscribe().then(value =>{
                setStatus('success')
                setData(value)
            }).catch(() => setStatus('error'))
        }

    }, [initialized, params.id])


    function alertError() {
        return <Alert variant="danger">
            <Alert.Heading>{t('pages.unsubscribe.alert.error.heading')}</Alert.Heading>
            <p>
                {t('pages.unsubscribe.alert.error.content')}
            </p>
            <hr/>
            <p className="mb-0">
                {t('pages.unsubscribe.alert.error.footer')}
            </p>
        </Alert>;
    }

    function alertSuccess(email: string) {
        return <Alert variant="success">
            <Alert.Heading>{t('pages.unsubscribe.alert.success.heading')}</Alert.Heading>
            <p>
                {t('pages.unsubscribe.alert.success.content', {mail: data})}
            </p>
            <hr/>
            <p className="mb-0">
                {t('pages.unsubscribe.alert.success.footer')}
            </p>
        </Alert>;
    }

    return (<Container className="vh-100 vw-100 d-flex justify-content-center align-items-center">
        {
            status === 'loading' || !initialized ? <MySpinner/> :
                status === 'error' ? alertError() : alertSuccess(data)

        }

    </Container>);

}

export default UnsubscribePage;